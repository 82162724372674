<template>
  <div class="app-item">
    <p>您的资料完善度：10%</p>
    <el-progress :text-inside="true" :stroke-width="18" :percentage="10" status="exception"></el-progress>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="昵称">
         <el-input v-model="form.nick_name" placeholder="请输入您的姓名"></el-input>
      </el-form-item>
      <el-form-item label="性别">
        <el-radio v-model="form.gender" label="男">男</el-radio>
        <el-radio v-model="form.gender" label="女">女</el-radio>
      </el-form-item>
      <el-form-item label="真实姓名">
         <el-input v-model="form.real_name" placeholder="请输入您的真实姓名"></el-input>
      </el-form-item>
       <el-form-item label="手机号">
        <input v-model="form.mobile" :disabled="disabled">
        <!-- <span class="change" @click="changer()">去修改</span> -->
      </el-form-item>
      <!-- <el-form-item label="所属部门">
        <el-input v-model="form.department_id" ></el-input>
      </el-form-item> -->
      <el-form-item label="职业">
         <el-input v-model="form.title" placeholder="请输入您的职业"></el-input>
      </el-form-item>

      <!-- <el-form-item label="所在城市">
        <el-select placeholder="请选择省/市/区" v-model="form.country">
          <el-option value="上海">上海</el-option>
          <el-option value="北京">北京</el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="积分">
        <input v-model="form.integral" :disabled="true">
      </el-form-item>
      <!-- <el-form-item label="详细地址">
        <el-input v-model="form.uaddress" placeholder="仅供活动寄送奖品，30字内"></el-input>
      </el-form-item> -->
      <el-button type="danger" @click="submitForm(form)">保存</el-button>
    </el-form>
  </div>
</template>
<script>
export default {
  data () {
    return {
      radio: '',
      disabled: true,
      form: {}
    }
  },
  methods: {
    // 修改手机号
    changer () {
      this.disabled = !this.disabled
    },
    // 获取用户信息
    getInfo () {
      this.$store.dispatch('getInfo').then(res => {
        console.log(res)
        this.form = res.info
        this.form.gender = String(res.info.gender)
      })
    },
    // 保存信息
    submitForm (form) {
      console.log(form)
      if (form.gender === '男') {
        form.gender = 1
      } else if (form.gender === '女') {
        form.gender = 2
      }
      this.$store.dispatch('updateInfo', form).then(res => {
        console.log(res)
        if (res.code === 0) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.getInfo()
        }
      })
    }
  },
  mounted () {
    this.getInfo()
  }
}
</script>
<style lang="less" scoped>
.app-item {
  p,
  .el-progress {
    margin: 10px 20px;
  }
  .el-form {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .el-form-item {
      width: 50%;
      margin: 20px 0;
      .el-input {
        width: auto;
      }
      input {
        padding: 0;
        border: none;
        background: none;
        width: 100px;
      }
      .change {
        cursor: pointer;
        color: @global-title-span-color;
      }
      // .el-input__inner {
      //   border: none !important;
      //   border-radius: 0%;
      //   border-bottom: 1px silid #ccc;
      // }
    }
    // .el-picker-panel{
    //  top:385px !important
    // }
  }
  .el-button--danger {
    padding: 12px 60px;
    margin: 40px auto;
  }
}
@media screen and (max-width: 700px) {
  .app-item {
    .el-form-item {
      width: 100% !important;
    }
  }
}
</style>
